import React, { useState, useContext, useEffect } from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import Badge from "@mui/material/Badge";
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import StepConnector from "@mui/material/StepConnector";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import styled from "@mui/material/styles/styled";
import stepConnectorClasses from "@mui/material/StepConnector/stepConnectorClasses";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { protoTimestampToDate } from "../commons/Utils";
import Constants, { CountryCodeToNameMapping, TenantStatusEnum, TenantStatusType } from "../../Constants";
import { ReactComponent as ClockCountdownIcon } from "./Icons/ClockCountdown.svg";
import CircleIcon from "@mui/icons-material/Circle";
import DoneIcon from "@mui/icons-material/Done";
import CloseAccountConfirmation from "./CloseAccountConfirmation";
import { useTranslation } from "react-i18next";
import HttpContext from "../../services/HttpContext";
import UserContext from "../../services/UserContext";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import InformationPanel from "../commons/InformationPanel";

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: "6.25rem",
  color: "#212121",
  border: "1px solid #00000040",
  textTransform: "none",
  marginRight: "1rem",
  padding: "0.5rem 1rem",
}));

function TableRowWithDetails({ row, expanded, onRowClick, isEven, uniqueKey, onApproveOrReject }) {
  const { PostRequest } = useContext(HttpContext);
  const {
    user: { user, email, roles },
  } = useContext(UserContext);

  let backgroundColor;
  if (expanded) {
    backgroundColor = "#E7E3DF";
  } else if (isEven) {
    backgroundColor = "#FFFF";
  } else {
    backgroundColor = "#F8F8F8";
  }
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };
  const { t } = useTranslation();
  const payload = {
    tenantIdentifier: row.tenantIdentifier,
    legalEntityName: row.legalEntityName,
    businessName: row.businessName,
    countryCode: row.countryCode,
    requestType: Constants.OFFBOARDING.OFFBOARD_TENANT,
    requestCreatedBy: email,
    requestStatus: Constants.OFFBOARDING.STATUS_PENDING,
    tenantStatus: Constants.OFFBOARDING.OFFBOARDING_INITIATED,
  };

  const QontoConnector = styled(StepConnector)(() => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 12,
      left: "calc(-50% + 7px)",
      right: "calc(50% + 7px)",
    },
    [`& .${stepConnectorClasses.line}`]: {
      marginTop: "4px",
      border: "1px dashed #909090",
    },
  }));

  const statusToStep = (status) => {
    switch (status) {
      case TenantStatusEnum.OFFBOARDING_INITIATED:
        return 0;
      case "PAUs notified":
        return 1;
      case "Access revoked":
        return 2;
      case TenantStatusEnum.OFFBOARDED:
        return 3;
      default:
        return -1;
    }
  };

  const stepperValues = [
    { title: t("CLIENT_OFFBOARDING:INITIAL_APPROVAL") },
    { title: t("CLIENT_OFFBOARDING:PAUS_NOTIFIED") },
    { title: t("CLIENT_OFFBOARDING:ACCCESS_REVOKED") },
    { title: t("CLIENT_OFFBOARDING:OFFBOARDING_COMPLETE") },
  ];

  const renderStepIcon = (activeStep, stepIndex, stepTitle, stepStatus) => {
    if (activeStep === stepIndex) {
      return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <IconButton
            disableRipple
            sx={{
              width: "34px",
              height: "34px",
              backgroundColor: TenantStatusType[row?.status] === "OFFBOARDED" ? "#6FB269" : "#5DB5F4",
              zIndex: 1500,
            }}
          >
            {TenantStatusType[row?.status] === "OFFBOARDED" ? (
              <DoneIcon sx={{ fontSize: "20px", color: "#FFF" }} />
            ) : (
              <ClockCountdownIcon />
            )}
          </IconButton>
          <Stack direction="column" width="10px" spacing={1}>
            <Typography variant="body1" sx={{ textAlign: "left", whiteSpace: "nowrap" }}>
              {stepTitle}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              fontStyle="italic"
              sx={{ textAlign: "left", whiteSpace: "nowrap" }}
            >
              {stepStatus}
            </Typography>
          </Stack>
        </Box>
      );
    }
    if (stepIndex < activeStep) {
      return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <IconButton
            disableRipple
            sx={{
              width: "34px",
              height: "34px",
              backgroundColor: "#6FB269",
              zIndex: 1500,
            }}
          >
            <DoneIcon sx={{ fontSize: "20px", color: "#FFF" }} />
          </IconButton>
          <Stack width="10px" spacing={1}>
            <Typography variant="body1" sx={{ textAlign: "left", whiteSpace: "nowrap" }}>
              {stepTitle}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              fontStyle="italic"
              sx={{ textAlign: "left", whiteSpace: "nowrap" }}
            >
              {stepStatus}
            </Typography>
          </Stack>
        </Box>
      );
    }
    return (
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
        <IconButton disableRipple sx={{ width: "34px", height: "34px", zIndex: 1500 }}>
          <CircleIcon sx={{ fontSize: "10px", color: "#909090" }} />
        </IconButton>
        <Grid container sx={{ pl: "8px", width: "10px" }}>
          <Grid item sx={{ textAlign: "left", whiteSpace: "nowrap" }}>
            <Typography variant="body1">{stepTitle}</Typography>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const getStepStatusText = (activeStep, stepIndex) => {
    if (stepIndex === activeStep) {
      return TenantStatusType[row?.status] === "OFFBOARDED" ? "Complete" : "In progress";
    }
    if (stepIndex < activeStep) {
      return "Complete";
    }
    return "Pending";
  };

  const primaryAuthorizedUsers = row.tenantUserDetails.filter((user) => user.relationship === "PAU");

  const details = [
    {
      label: t("CLIENT_OFFBOARDING:BUSINESS"),
      value: `${row.legalEntityName}\n${row.businessName}\n${CountryCodeToNameMapping[row?.countryCode]}\n${
        row.businessEmail
      }\n${row.phone}`,
    },
    {
      label: t("CLIENT_OFFBOARDING:SUBSCRIPTION_PLAN"),
      value: `${row.planName}\n${t("CLIENT_OFFBOARDING:STARTS_ON")} ${protoTimestampToDate(row.subscriptionDate)}`,
    },
    ...primaryAuthorizedUsers.map((user, index) => ({
      label: `${t("CLIENT_OFFBOARDING:PRIMARY_AUTHORIZED_USER")} ${index + 1}`,
      value: `${user.name}\n${user.email}\n${user.phone}`,
    })),
  ];

  const [isChecked, setIsChecked] = useState(false);

  const isApproverSame = (email === row.updatedBy && !roles.includes(Constants.USER_ROLE.ADMIN));

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleApproveClick = async () => {
    const approveClosurePayload = {
      approvedBy: user,
      requestType: "OFFBOARD_TENANT",
      requestStatus: "SERVICE_REQUEST_STATUS_APPROVED",
      tenantIdentifier: row.tenantIdentifier,
      legalEntityName: row.legalEntityName,
      businessName: row.businessName,
      countryCode: row.countryCode,
    };

    try {
      await PostRequest(Constants.REACT_APP_API_HANDLE_CLOSE_REQUEST, approveClosurePayload);
      onApproveOrReject("Request approved succesfully", "success", false);
    } catch (e) {
      console.error(e);
      onApproveOrReject("Failed to approve request", "failure", true);
    }
  };

  const handleRejectClick = async () => {
    const rejectClosurePayload = {
      approvedBy: user,
      requestType: "OFFBOARD_TENANT",
      requestStatus: "SERVICE_REQUEST_STATUS_REJECTED",
      tenantIdentifier: row.tenantIdentifier,
      legalEntityName: row.legalEntityName,
      businessName: row.businessName,
      countryCode: row.countryCode,
    };

    try {
      await PostRequest(Constants.REACT_APP_API_HANDLE_CLOSE_REQUEST, rejectClosurePayload);
      onApproveOrReject("Request rejected successfully", "success", false);
    } catch (e) {
      console.error(e);
      onApproveOrReject("Failed to reject request", "failure", true);
    }
  };

  return (
    <React.Fragment key={row.id}>
      <TableRow sx={{ backgroundColor, height: "56px" }} data-testid={`${uniqueKey}-collapsed-view`}>
        <TableCell sx={{ pl: 4 }}>
          {row.status === "OFFBOARDING_INITIATED" && <Badge color="warning" variant="dot" />}
        </TableCell>
        <TableCell sx={{ pl: 4 }}>{row?.legalEntityName}</TableCell>
        <TableCell sx={{ pl: 4 }}>{CountryCodeToNameMapping[row?.countryCode]}</TableCell>
        <TableCell sx={{ pl: 4 }}>{row?.businessName}</TableCell>
        <TableCell sx={{ pl: 4 }}>SYSTEM</TableCell>
        <TableCell sx={{ pl: 4 }}>{protoTimestampToDate(row?.createdAt)}</TableCell>
        <TableCell sx={{ pl: 4 }}>{TenantStatusType[row?.status]}</TableCell>
        <TableCell sx={{ pl: 4 }}>
          {expanded ? (
            <ExpandLessIcon
              onClick={() => {
                onRowClick(row?.tenantIdentifier);
              }}
              sx={{ cursor: "pointer" }}
            />
          ) : (
            <ExpandMoreIcon
              onClick={() => {
                onRowClick(row?.tenantIdentifier);
              }}
              sx={{ cursor: "pointer" }}
            />
          )}
        </TableCell>
      </TableRow>
      {expanded && (
        <TableRow data-testid={`${uniqueKey}-expanded-view`}>
          <TableCell colSpan={10} sx={{ padding: 0 }}>
            <Accordion expanded={expanded} sx={{ backgroundColor: "#FFFFFF", px: 2 }}>
              <AccordionDetails>
                <Box>
                  {(TenantStatusType[row?.status] === "OFFBOARDING_INITIATED" ||
                    TenantStatusType[row?.status] === "OFFBOARDING_APPROVED" ||
                    TenantStatusType[row?.status] === "OFFBOARDED") && (
                    <Grid container>
                      <Grid item xs={9}>
                        <Box sx={{ mt: 4, mb: 4 }}>
                          <Typography variant="h6" data-testid={`${uniqueKey}-tableRow-heading`} marginBottom="24px">
                            {t("CLIENT_OFFBOARDING:PROGRESS_STATUS")}
                          </Typography>
                          <Stepper
                            activeStep={statusToStep(row.status)}
                            alternativeLabel
                            connector={<QontoConnector />}
                          >
                            {stepperValues.map((step, index) => (
                              <Step key={index}>
                                <StepLabel
                                  StepIconComponent={() =>
                                    renderStepIcon(
                                      statusToStep(row.status),
                                      index,
                                      step.title,
                                      getStepStatusText(statusToStep(row.status), index),
                                    )
                                  }
                                />
                              </Step>
                            ))}
                          </Stepper>
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                  <Box sx={{ mt: 1 }} data-testid={`${uniqueKey}-tableRowClientDetails-container`}>
                    <Typography variant="h6">{t("CLIENT_OFFBOARDING:CLIENT_DETAILS")}</Typography>
                    <Grid container sx={{ mt: "16px" }}>
                      {details.map((detail, index) => (
                        <Grid item xs={2} key={index}>
                          <Box sx={{ width: "164px" }}>
                            <Box lineHeight={1}>
                              <Typography variant="body1" sx={{ color: "#212121bf", fontSize: "14px" }}>
                                {detail.label}
                              </Typography>
                            </Box>
                            <Box sx={{ wordBreak: "keep-all", whiteSpace: "pre-wrap", lineHeight: 1.25 }}>
                              <Typography variant="body1" sx={{ fontSize: "14px" }}>
                                {detail.value}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                  {TenantStatusType[row?.status] === "OFFBOARDING_INITIATED" && !isApproverSame && (
                    <Box>
                      <Box marginTop="24px" width="1056px">
                        <InformationPanel
                          type="alert"
                          heading={t("CLIENT_OFFBOARDING:CLOSE_ACCOUNT_REQUEST_HEADING")}
                          body={t("CLIENT_OFFBOARDING:CLOSE_ACCOUNT_REQUEST_BODY")}
                        />
                      </Box>
                      <Box marginTop="24px">
                        <Typography variant="body2" fontWeight={600}>
                          {t("CLIENT_OFFBOARDING:DISCLAIMER")}
                        </Typography>
                        <Typography variant="body2">{t("CLIENT_OFFBOARDING:DISCLAIMER_BODY")}</Typography>
                      </Box>
                      <Box marginTop="16px">
                        <Checkbox checked={isChecked} onChange={handleCheckboxChange} />
                        <Typography variant="body2" display="inline">
                          {t("CLIENT_OFFBOARDING:DISCLAIMER_APPROVAL")}
                        </Typography>
                      </Box>
                      <Box marginTop="16px">
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{
                            backgroundColor: "#EA3E27",
                            color: "white",
                            textTransform: "none",
                            borderRadius: "6.25rem",
                            marginRight: "1rem",
                          }}
                          disabled={!isChecked}
                          onClick={handleApproveClick}
                        >
                          {t("CLIENT_OFFBOARDING:APPROVE")}
                        </Button>
                        <StyledButton disabled={!isChecked} onClick={handleRejectClick}>
                          {t("CLIENT_OFFBOARDING:REJECT")}
                        </StyledButton>
                      </Box>
                    </Box>
                  )}
                  {TenantStatusType[row?.status] === "ACTIVE" && (
                    <Box marginTop="24px">
                      <StyledButton variant="secondary">{t("CLIENT_OFFBOARDING:PAUSE_ACCOUNT")}</StyledButton>
                      <StyledButton variant="secondary" onClick={handleOpenDialog}>
                        {t("CLIENT_OFFBOARDING:CLOSE_ACCOUNT")}
                      </StyledButton>
                    </Box>
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
          </TableCell>
        </TableRow>
      )}
      <CloseAccountConfirmation open={isDialogOpen} setIsDialogOpen={setIsDialogOpen} payload={payload} />
    </React.Fragment>
  );
}

export default TableRowWithDetails;
