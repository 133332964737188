const Constants = {
  ACTION_REQUIRED: "ACTION_REQUIRED",
  UNASSIGNED: "UNASSIGNED",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
  VERIFY: "VERIFY",
  VERIFIED: "VERIFIED",
  REJECT: "REJECT",
  REJECTED: "REJECTED",
  DASHBOARD: "DASHBOARD",
  DASHBOARD_URL: "/dashboard",
  CONSUMER_ON_BOARDING_URL: "/consumer-onboarding",
  PROVIDER_ON_BOARDING_URL: "/provider-onboarding",
  USER_MANAGEMENT_URL: "/user-management",
  ROLE_MANAGEMENT_URL: "/role-management",
  SUBSCRIPTION_URL: "/subscription",
  HELPDESK_URL: "/helpdesk",
  CLIENT_ONBOARDING_URL: "/",
  NEW_CLIENT_URL: "/new-client",
  LOGIN_URL: "/login",
  FILTERING: "/status",
  USERS_URL: "/users",
  FORBIDDEN_URL: "/403",
  SYNTHIX: "synthix",
  BREAKINGWAVE: "breakingwave",
  REACT_APP_API_ROOT: "/core",
  REACT_APP_API_LOGIN: "/login",
  REACT_APP_API_LOGOUT: "/logout",
  REACT_APP_API_FETCH_CLIENT_ONBOARDING_LIST: "/customers",
  REACT_APP_API_CREATE_NEW_CLIENT_REQUEST: "/se/customers",
  REACT_APP_API_MODIFY_REQUEST_STATUS: "/coo/customers",
  REACT_APP_RESEND_INVITATION_LINK: "/resendInvitation",
  REACT_APP_API_FETCH_PRICING_PLAN_LIST: "/customers/plans",
  REACT_APP_API_CHECK_TENANT_EXISTS: "/tenant/check",
  REACT_APP_API_FETCH_ENV_VARIABLE: "/config/env",
  REACT_APP_API_FETCH_ROLES: "/roles",
  REACT_APP_API_FETCH_SERVICE_REQUESTS: "/service-requests",
  REACT_APP_API_FETCH_TENANTS: "/tenants",
  REACT_APP_API_FETCH_ALL_CLIENTS: "/all-clients",
  REACT_APP_API_CREATE_PAUSE_REQUEST: "/se/service-request/pause-tenant",
  REACT_APP_API_UPDATE_PAUSE_REQUEST: "/coo/service-request/update",
  REACT_APP_API_HANDLE_CLOSE_REQUEST: "/service-request/handle-close-tenant",
  REACT_APP_API_CREATE_CLOSE_REQUEST: "/service-request/close-tenant",
  ERRORS: {
    ERROR_DOC: "ERROR_DOC",
  },
  CommentTypes: {
    BUSINESS_DOCUMENT: "BUSINESS_DOCUMENT",
    LEGAL_BUSINESS_NAME_CHECK: "LEGAL_BUSINESS_NAME_CHECK",
    COUNTRY_RISK_CHECK: "COUNTRY_RISK_CHECK",
    IDENTITY_DOCUMENT: "IDENTITY_DOCUMENT",
    BANK_ACCOUNT_DETAILS: "BANK_ACCOUNT_DETAILS",
    OFFICIAL_EMAIL_CHECK: "OFFICIAL_EMAIL_CHECK",
    OVERALL: "OVERALL",
  },
  TICKET_STATUS: {
    UNASSIGNED: "OPEN",
    IN_PROGRESS: "IN_PROGRESS",
    AWAITING_INPUT: "AWAITING_INPUT",
    RESOLVED: "RESOLVED",
    CLOSED: "CLOSED",
  },
  INVALID_REFERENCE_ID: "INVALID_REFERENCE_ID",
  APPLICATION_APPROVAL_STATUS: {
    APPROVED: "APPROVED",
    REJECTED: "REJECTED",
    RETURNED: "RETURNED",
    VERIFIED: "VERIFIED",
  },
  APPLICATION_TABS: {
    BUSINESS_DETAILS: "BUSINESS_DETAILS",
    BUSINESS_REPRESENTATIVES: "BUSINESS_REPRESENTATIVES",
    BUSINESS_OWNERS: "BUSINESS_OWNERS",
    BUSINESS_EXECUTIVES: "BUSINESS_EXECUTIVES",
    BANK_ACCOUNT_DETAILS: "BANK_ACCOUNT_DETAILS",
  },
  SUB_TYPES: {
    CONSUMER_ON_BOARDING: "CONSUMER_ON_BOARDING",
    PROVIDER_ON_BOARDING: "PROVIDER_ON_BOARDING",
    PRODUCT_SUBSCRIPTION: "PRODUCT_SUBSCRIPTION",
  },
  BUSINESS_SUB_TYPE: {
    PRIVATE_CORPORATION: "PRIVATE_CORPORATION",
    UNINCORPORATED_ASSOCIATION: "UNINCORPORATED_ASSOCIATION",
    REGISTERED_ASSOCIATION: "REGISTERED_ASSOCIATION",
  },
  BUSINESS_RELATION_TYPE: {
    MANAGEMENT: "MANAGEMENT",
  },
  ACCESS_MANAGEMENT: {
    USER_MANAGEMENT: "USER_MANAGEMENT",
    ROLE_MANAGEMENT: "ROLE_MANAGEMENT",
  },
  USER_ROLE: {
    ADMIN: "ADMIN",
    COO: "COO",
    SE: "SE",
  },
  OFFBOARDING: {
    OFFBOARD_TENANT: "OFFBOARD_TENANT",
    STATUS_PENDING: "SERVICE_REQUEST_STATUS_PENDING",
    OFFBOARDING_INITIATED: "STATUS_OFFBOARDING_INITIATED",
    OFFBOARDING_REQUESTED: "STATUS_OFFBOARDING_REQUESTED",
  },
};

export const CountryCodeToNameMapping = {
  IN: "India",
  GB: "United Kingdom",
  DE: "Germany",
};

export const roleOptions = [
  { value: "CFO", label: "Chief Financial Officer (CFO)" },
  { value: "CEO", label: "Chief Executive Officer (CEO)" },
  { value: "CTO", label: "Chief Technology Officer (CTO)" },
  { value: "FINANCE_LEAD", label: "Finance Lead" },
  { value: "SALES_MANAGER", label: "Sales Manager" },
  { value: "OTHERS", label: "Others" },
];

export const serviceRequestStatusEnum = {
  UNSPECIFIED: 0,
  PENDING: 1,
  APPROVED: 2,
  REJECTED: 3,
};
export const serviceRequestStatusType = {
  [serviceRequestStatusEnum.UNSPECIFIED]: "UNSPECIFIED",
  [serviceRequestStatusEnum.PENDING]: "PENDING",
  [serviceRequestStatusEnum.APPROVED]: "APPROVED",
  [serviceRequestStatusEnum.REJECTED]: "REJECTED",
};
export const tenantRequesType = {
  0: "TYPE_UNSPECIFIED",
  1: "Pause tenant",
  2: "Unpause tenant",
};

export const TenantStatusEnum = {
  UNSPECIFIED: 0,
  ACTIVE: 1,
  INACTIVE: 2,
  PAUSED: 3,
  PAUSE_REQUESTED: 4,
  OFFBOARDING_REQUESTED: 5,
  OFFBOARDING_INITIATED: 6,
  OFFBOARDING_APPROVED: 7,
  OFFBOARDED: 8,
};

export const TenantStatusType = {
  [TenantStatusEnum.UNSPECIFIED]: "UNSPECIFIED",
  [TenantStatusEnum.ACTIVE]: "ACTIVE",
  [TenantStatusEnum.INACTIVE]: "INACTIVE",
  [TenantStatusEnum.PAUSED]: "PAUSED",
  [TenantStatusEnum.PAUSE_REQUESTED]: "PAUSE_REQUESTED",
  [TenantStatusEnum.OFFBOARDING_REQUESTED]: "OFFBOARDING_REQUESTED",
  [TenantStatusEnum.OFFBOARDING_INITIATED]: "OFFBOARDING_INITIATED",
  [TenantStatusEnum.OFFBOARDING_APPROVED]: "OFFBOARDING_APPROVED",
  [TenantStatusEnum.OFFBOARDED]: "OFFBOARDED",
};

export default Constants;
