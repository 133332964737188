import SvgIcon from '@mui/material/SvgIcon';

const CheckboxCheckedIcon = () => {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 3H19C20.1 3 21 3.9 21 5V19C21 20.1 20.1 21 19 21H5C3.9 21 3 20.1 3 19V5C3 3.9 3.9 3 5 3ZM16.6 7.59961L18 8.99961L10 16.9996L6 12.9996L7.4 11.5996L10 14.1996L16.6 7.59961Z"
          fill="#313131"
        />
      </svg>
    </SvgIcon>
  );
};

export default CheckboxCheckedIcon;
