import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { CalendarIcon } from "./Icons/CalendarIcon";

const DatePicker = (props) => {
  const { calendarPosition = "end", format = "dd/MM/yyyy", placeholder = "" } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MuiDatePicker
        format={format}
        {...props}
        slots={{
          openPickerIcon: CalendarIcon,
          ...(props.slots || {}),
        }}
        slotProps={{
          openPickerButton: {
            sx: { margin: "0rem", marginLeft: "0rem" },
          },
          leftArrowIcon: {
            sx: { color: "#313131" },
          },
          rightArrowIcon: {
            sx: { color: "#313131" },
          },
          openPickerIcon: {
            sx: { color: "#313131" },
          },
          switchViewIcon: {
            sx: { color: "#313131" },
          },
          day: {
            disableRipple: true,
          },
          calendarHeader: {
            sx: { margin: "0.5rem 0rem" },
          },
          inputAdornment: { position: calendarPosition },
          textField: { placeholder },
          ...(props.slotProps || {}),
          desktopPaper: {
            sx: { borderRadius: "1rem", boxShadow: "0.5rem" },
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
