import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Checkbox from "@mui/material/Checkbox";
import DatePicker from "./DatePicker";
import Constants from "../../Constants";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import HttpContext from "../../services/HttpContext";
import CheckboxUnCheckedIcon from "../AllClients/Icons/CheckboxUnCheckedIcon";
import CheckboxCheckedIcon from "../AllClients/Icons/CheckboxCheckedIcon";

const StyledButton = styled(Button)(({ theme, variant }) => ({
  borderRadius: "1.25rem",
  textTransform: "none",
  ...(variant === "outlined" && {
    border: "1px solid #31313180",
    color: "#000000",
    marginRight: "1rem",
  }),
  ...(variant === "contained" && {
    background: "#E03400",
    border: "none",
    color: "#FFFFFF",
  }),
}));

function CloseAccountConfirmation({ open, setIsDialogOpen, payload }) {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);
  
  const handleCheckboxToggle = (event) => {
    const isChecked = event.target.checked;
    setChecked(isChecked);
    setChosenDate(isChecked ? new Date("1970-01-01") : null);
  };
  
  const [chosenDate, setChosenDate] = useState(null);
  const handleDateChange = (date) => {
    setChosenDate(date);
    setChecked(false);
  };
  const today = new Date();
  const startDate = new Date();
  startDate.setDate(today.getDate() + 8);

  const maxDate = new Date(startDate);
  maxDate.setMonth(startDate.getMonth() + 2);

  const onClose = () => {
    setChecked(false);
    setChosenDate(null);
    setIsDialogOpen(false);
  };

  const isCloseButtonDisabled = !checked && !chosenDate;

  const { PostRequest } = useContext(HttpContext);
  const navigate = useNavigate();
  const handleConfirm = async () => {
    try {
      const updatedPayload = {
        ...payload,
        commencementDate: chosenDate,
      };
      const response = await PostRequest(Constants.REACT_APP_API_CREATE_CLOSE_REQUEST, updatedPayload);
      if (response && response.message) {
        setIsDialogOpen(false);
        navigate(0);
      } else {
        console.error("Unexpected response format or missing message.");
      }
    } catch (error) {
      console.error("Error during handleConfirm:", error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: "1rem",
        },
      }}
    >
      <DialogTitle
        sx={{
          width: "26.5rem",
          height: "1.625rem",
          background: "#D8D2CB",
          display: "flex",
          alignItems: "center",
          position: "relative",
          paddingRight: "0.75rem",
          paddingTop: "1.5rem",
          paddingBottom: "0.75rem",
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Typography
            variant="h6"
            sx={{
              width: "100%",
              opacity: 1,
            }}
          >
            {t("CLIENT_OFFBOARDING:CLOSE_ACCOUNT_PROMPT")}
          </Typography>
        </Box>
        <IconButton onClick={onClose} size="small" sx={{ position: "absolute", right: "1rem" }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{
          width: "25.75rem",
          display: "flex",
          flexDirection: "column",
          padding: "0 1.5rem 1.5rem",
        }}
      >
        <Typography
          sx={{
            margin: "1rem 0 1.5rem",
          }}
        >
          {t("CLIENT_OFFBOARDING:CLOSE_CONFIRMATION_CONTENT")}
        </Typography>
        <Box
          sx={{
            backgroundColor: "#F8F8F7",
            padding: "1rem 0.5rem 0.5rem",
            marginBottom: "1rem",
            borderRadius: "0.5rem",
          }}
        >
          <Box marginBottom="0.625rem">
            <DatePicker
              label={t("CLIENT_OFFBOARDING:SELECT_CLOSURE_DATE")}
              value={checked ? null : chosenDate}
              onChange={handleDateChange}
              disablePast
              minDate={startDate}
              maxDate={maxDate}
              slotProps={{
                textField: {
                  sx: {
                    width: "100%",
                  },
                },
                calendarHeader: {
                  sx: {
                    margin: "0.6rem",
                  },
                },
              }}
              disabled={checked}
            />
          </Box>
          <Box display="flex" alignItems="center" justifyContent="flex-start" sx={{ cursor: "pointer" }}>
            <Checkbox
              sx={{ padding: "0rem" }}
              icon={<CheckboxUnCheckedIcon />}
              checkedIcon={<CheckboxCheckedIcon />}
              checked={checked}
              onChange={handleCheckboxToggle}
            />
            <Typography variant="p2" marginLeft="0.25rem">
              {t("CLIENT_OFFBOARDING:AS_SOON_AS_POSSIBLE")}
            </Typography>
          </Box>
        </Box>
        <Box marginBottom="1.5rem">
          <Typography variant="p2Strong">{t("CLIENT_OFFBOARDING:NOTE_STRONG")}</Typography>
          <Typography variant="p2">{t("CLIENT_OFFBOARDING:NOTE_PROMPT")}</Typography>
        </Box>

        <Box
          sx={{
            borderTop: "1px dashed #CCCCCC",
            width: "auto",
            padding: "0 1.5rem",
          }}
        />
        <DialogActions
          sx={{
            justifyContent: "flex-end",
            padding: "1.5rem 0 0",
          }}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <StyledButton variant="outlined" onClick={onClose}>
              {t("CLIENT_OFFBOARDING:DO_NOT_PROCEED_BUTTON")}
            </StyledButton>
            <StyledButton variant="contained" onClick={handleConfirm} disabled={isCloseButtonDisabled}>
              {t("CLIENT_OFFBOARDING:CONFIRM_CLOSE_BUTTON")}
            </StyledButton>
          </Box>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

export default CloseAccountConfirmation;
