const en = {
  CLIENT_ONBOARDING: {
    CLIENT_ONBOARDING: "Client Onboarding",
    LEGAL_ENTITY_NAME: "Legal entity name",
    LOCATION: "Location",
    NAME_ON_FRONTIER: "Name on frontier",
    ACCOUNT_CREATED_BY: "Account created by",
    ACCOUNT_CREATED_ON: "Account created on",
    STATUS: "Status",
  },

  APPLICATION: {
    LEFT_MENU: {
      LOGOUT: "Logout",
    },
  },

  CLIENT_OFFBOARDING: {
    PAUSE_ACCOUNT: "Pause account",
    CLOSE_ACCOUNT: "Close account",
    CLOSE_CONFIRMATION_CONTENT:
      "This action will revoke this organisations’ access to the Frontier finance platform and their data on the platform will be removed. Once authorised, existing or upcoming transactions in the following 48 hours only will be honoured.",
    DO_NOT_PROCEED_BUTTON: "Do not proceed",
    CONFIRM_CLOSE_BUTTON: "Confirm close account",
    INITIAL_APPROVAL: "Initial approval",
    PAUS_NOTIFIED: "PAUs notified",
    ACCCESS_REVOKED: "Access revoked",
    OFFBOARDING_COMPLETE: "Offboarding complete",
    BUSINESS: "Business",
    PRIMARY_AUTHORIZED_USER: "Primary authorized user",
    SUBSCRIPTION_PLAN: "Subscription plan",
    STARTS_ON: "Starts on",
    PROGRESS_STATUS: "Progress status",
    CLIENT_DETAILS: "Client details",
    CLOSE_ACCOUNT_PROMPT: "Want to close this account?",
    CLOSE_ACCOUNT_REQUEST_HEADING: "Close account request received",
    CLOSE_ACCOUNT_REQUEST_BODY: "A request has been made to close this account as soon as possible.",
    DISCLAIMER: "Disclaimer",
    DISCLAIMER_BODY:
      "By approving, you indicate that you have reviewed all the details and agree to proceed with closing this account and removing the business from Frontier Finance.",
    DISCLAIMER_APPROVAL: "Please tick if you agree and are happy to proceed.",
    APPROVE: "Approve",
    REJECT: "Reject",
    AS_SOON_AS_POSSIBLE: "As soon as possible",
    NOTE_PROMPT: "that this action requires approval.",
    NOTE_STRONG: "Note: ",
    SELECT_CLOSURE_DATE: "Select closure date",
  },
};

export default en;
